import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  datatable: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important'
    }
  }
});
export const Tables = ({ rows, columns }) => {
  const classes = useStyles();
  return (
    <div className="tablestyle">
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        disableColumnFilter={true}
        pageSizeOptions={[25, 50, 100]}
        disableColumnMenu
        autoHeight={true}
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
        classes={{ root: classes.datatable }}
      />
    </div>
  );
};
